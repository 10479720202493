.builder-iframe {
    margin: auto;
    height: 75vh;
    border: 10px solid #111;
    border-radius: 22px;
    box-shadow: 0 0 5px #666;
    width: 95%;
    max-width:400px
}

#bbPreviewModal .bb-preview-frame {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

#bui-editor-preview {
    max-width: 1800px;
}

.bb-pages-table {
    border-radius: 5px !important;
}

.bb-pages-table th {
    background: var(--secondary-color);
    color: white;
}

.bb-pages-table .bb-sort {
    justify-content: flex-end;
}

.bb-pages-table td {
    vertical-align: middle;
}

.bb-pages-table td a {
    color: #111;
    text-decoration: underline;
}

.bb-pages-table td a:hover {
    color: var(--primary-color)
}

.bb-sort li a {
    text-decoration: none !important;
}

.bb-preview-link {
    background: var(--secondary-color);
    border-radius: 5px;
    padding: 4px 8px;
    text-decoration: none;
    color: #fff !important;
    font-size: 13px;
    width: 83px;
    text-align: center;
    margin: auto;
    margin-bottom: -5px;
    z-index: 999;
    position: relative;
    top: 30px;
}

.bb-preview-link:hover {
    background: var(--primary-color);
}

.bb-preview-link i::before {
    color: white !important;
}

.bb-page-edit-options {
    padding-bottom: 2rem;
}

#page-properties {
    flex-direction: column;
    margin: 0 0 1rem;
}

#page-properties hr:last-child {display: none;}

#page-properties .bb-input-labels {
    display: flex;
    align-items: center;
}

.bb-page-edit-options-container .d-flex input  {
    min-width: 300px;
}

.bb-page-edit-options-container .d-flex .bb-input-labels label {
    margin: 0;
    min-width: 150px;
}

#bbPreviewModal .modal-dialog {
    max-width: 2000px !important;
}

#bbPreviewModal .bb-preview-frame {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

#bbPreviewModal .modal-body {
    background: #ddd;
    min-height: 90vh;
}

#bbPreviewModal .form-select {
    max-width: var(--ui-max-width);
    margin: auto;
}

/* previewer */
.bb-p-desktop {
    width: 90% !important
}

.bb-p-tablet-landscape {
    width: 1000px !important;
    height: 600px !important;
}

.bb-p-tablet-portrait {
    width: 600px !important;
    height: 80vh !important;
}

.bb-p-mobile-landscape {
    width: 800px !important;
    height: 400px !important;
}

.bb-p-mobile-portrait {
    width: 400px !important;
    height: 70vh !important;
}

.dataTables_length select,
.dataTables_length input,
.dataTables_wrapper select,
.dataTables_wrapper input {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    line-height: 2;
    color: #333;
}

.dataTables_length .dataTables_length,
.dataTables_length .dataTables_filter,
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    display: inline-flex;
}

.dataTables_filter {
    float: right;
    margin-right: 0px !important;
}

.paginate_button {
    min-width: 1rem;
    display: inline-block;
    text-align: center;
    padding: 6px;
    margin-top: -1rem;
    border: 1px solid #eee;
    background: white;
    text-decoration: none;
    color: #111;
}

.paginate_button:not(.previous) {
    border-left: none;
}

.paginate_button.previous {
    border-radius: 8px 0 0 8px;
    min-width: 6rem;
}

.paginate_button.next {
    border-radius: 0 8px 8px 0;
    min-width: 6rem;
}

.paginate_button:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary-color);
}

.no-sort::after {
    display: none !important;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    font-family: Material Icons !important;
    opacity: 1 !important
}

table.dataTable thead .sorting:after {
    content: "\e8d5" !important;
}

table.dataTable thead .sorting_desc:after {
    content: "\e5d8" !important;
}

table.dataTable thead .sorting_asc:after {
    content: "\e5db" !important;
}

.current {
    background: var(--primary-color) !important;
    color: #fff!important;
}

.bb-bulk-options {
    float: right;
    width: 159px;
    border-color: #eee;
    margin-bottom: 0;
    padding-bottom: 7px;
    margin-left: 10px;
    position: relative;
    top: 9px;
    padding: 5px;
}

.bb-new-page {
    border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
}

.target-uid {
    cursor: pointer;
    font-weight: 600;
}



/* edit single page */
.bb-page-edit-panel,
.bb-page-edit-content {
    background: #fff;
    height: 100%;
    border-radius: var(--border-radius);
    box-shadow: 0px 0px 3px #eee;
}

#myTabContent2 .bb-html-elements {
    height: 40vh !important;
}

.bb-html-elements label {
    text-align: center;
    padding: 16px 43px 9px;
    box-shadow: 0px 0px 2px #ddd;
    margin: 13px;
}

.bb-html-elements label:hover {
    cursor: pointer;
}

.bb-html-elements span.material-symbols-outlined {
    font-size: 70px;
    color: var(--primary-color);
}

.bb-html-elements label:hover span.material-symbols-outlined {
    color: #111;
    cursor: pointer;
}

.bb-widgets .panel-type img {
    width: 100%;
}

.bb-widgets .panel-type:hover {
    opacity: 0.8;
    cursor: pointer;
}

.bb-cat-scroll {
    overflow: scroll;
    max-height: 200px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
}

/* editing */
.bb-add-page-content {
    padding: 1rem;
    background: #f9f9f9;
    border: 1px dashed #eee;
    margin-top: 20px;
}

.bb-edit {
    position: relative;
    border: 1px solid #eee;
    padding: 10px;
    box-shadow: 0px 0px 2px #eee;
    margin: 5px;
    background: #fff;
    overflow: hidden;
}

.bb-edit a.bb-add-cont {
    margin: auto;
    margin: auto;
    align-items: center;
    justify-content: center;
    display: flex;
}

.bb-edit img {
    max-width: 100%;
}

.bb-edit-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #369;
    color: white;
    padding: 4px 5px 0px;
    border-radius: 5px;
    margin: 4px;
    opacity: 1;
    box-shadow: 0px 0px 5px #eee;
}

a.bb-row-edit .bi:hover::before, a.bb-col-edit .bi:hover::before {
    color: var(--primary-color)!important;
}

.bb-edit-icon span {
    font-size: 18px;
}

.bb-edit-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

.bb-editor-row {
    border: 1px solid #eee;
    background: #f9f9f9;
    margin-top: 34px;
}

.bb-row-options {
    position: relative;
    top: -20px;
    background: none;
    padding: 5px;
}

.bb-row-options span {
    background: #f9f9f9;
    /* color: white; */
    margin: 4px;
    border-radius: 5px;
    font-size: 18px;
    padding: 4px 5px 5px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 5px #eee;
}

.bb-row-options span:hover {
    cursor: pointer;
    background: #369;
    color: #fff;
}

.bb-drag span:hover {
    cursor: move;
}

.ql-container {
    min-height: 435px;
}

.ql-tooltip {
    left: 5px !important
}

/* category */
.list-group-item {
    min-width: 400px;
    flex-direction: row;
    display: flex;
}

.bb-json-header {
    display: none;
}

/* tabs */
#code textarea {
    min-height: 800px;
    background-color: #f8f8f8;
    font-family: 'Courier New', Courier, monospace;
}

#code hr {
    display: none
}

#code-editor-detail {
    margin-bottom: 1rem;
}

/* new beebot editor */
.bb-row {
    display: flex;
    padding: 1.5rem 0 0.5rem;
    flex-direction: column;
    background: #f9f9f9;
    margin: 1.5rem 0 3.5rem 0;
    border-radius: 0 0 3px 3px!important;
    border: 1px solid var(--page-builder-border);
    position: relative;
    align-content: space-between;
    width: 100%;
}

.tab-pane {
    border: none;
    padding: 1rem 0;
}

.bb-row-edit-button {
    position: absolute;
    background: #f9f9f9;
    border-radius: 3px 3px 0 0;
    padding: 0.2rem;
    text-align: center;
    top: -21px;
}

.bb-row-edit-button .bi:before {
    margin-right: 0px;
    margin-top: 0px;
    color: black;
}

.bb-row-label, .bb-col-label {
    background: #0257d5;
    color: #fff;
    padding: 0.1rem .5rem;
    left: 0px;
    font-size: .7rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px 3px 0 0;
    left: -1px;
    display: flex;
    align-items: center;
}

.bb-row-edit {
    right: -1px;
    border: 1px solid var(--page-builder-border);
    border-bottom: 0px;
    padding: 4px 8px;
    top: -25px;
}

.bb-row-delete {
    right: 10px;
    border:1px solid var(--page-builder-border);
    display: none;
}

/* column edit */
.bb-col-label {
    background:#0e8668;
    display: inline;
    position: absolute;
    top: -20px;
}

.bb-edit-col-label {
    background:#0e8668;
    font-size: .7rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px 3px 0 0;
    margin-left: 0.5rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    color: white;
    display: flex;
    align-items: center;
}

.bb-row .bb-col {
    width: 100%;
    background: white;
    margin: 1.2rem 0.5rem 0rem;
    position: relative;
    border: 1px solid var(--page-builder-border);
    border-radius: 0 0 3px 3px;
    min-height: 40px;
}

.bb-col-delete {
    display: none;
}

a.bb-col-edit-button {
    position: absolute;
    top: -21px;
    right: -1px;
    background: white;
    text-align: center;
    padding: 0px 6px;
    border-radius: 3px 3px 0 0;
    border: 1px solid var(--page-builder-border);
    border-bottom: 0px;
}

a.bb-col-edit-button .bi::before {
    color:black;
    padding-right: 0;
    margin-right: 0;
}

/* if page has the old grid layout */
.bb-col-grid {
    columns: 3;
    gap: 0.8em;
}

.bb-col-grid .bb-panel {
    overflow: hidden;
    display: inline-block!important;
    flex: none!important;
    width: 96%;
    box-sizing: border-box;
}

.bb-col-grid .bb-start-chat {
    column-span: all;
    display: block!important;
}

/* panel edit */
.tab-content .bb-panel {
    margin: 0.5rem;
    background: var(--secondary-color);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 0rem;
    justify-content: center;
    margin-top: 1rem;
    position: relative;
}

.tab-content .bb-panel-edit .bi::before {
    color: #111;
}

.tab-content .bb-panel-edit .bi:hover::before {
    color: var(--primary-color);
}

.tab-content .bb-panel-info {
    text-transform: uppercase;
    font-size: .7rem;
    font-weight:bold;
    color: #fff;
    margin: 0.5rem 0.5rem 0 0.5rem;
}

.bb-edit-panel-label {
    background: var(--secondary-color);
    font-size: .7rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px 3px 0 0;
    margin-left: 0.5rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    color: white;
    display: flex;
    align-items: center;
}

.tab-content .bb-panel-data {
    margin: 0.5rem;
    background: white;
    border-radius: 3px;
    padding: 0.4rem;
}

.bb-panel-edit {
    margin: 0.5rem 0.25rem 0rem auto;
}

.bb-panel-edit-button i::before {
    color: white!important;
}

.bb-panel-edit-button i:hover::before {
    color: var(--primary-color)!important;
}

.bb-panel-data h4 {
    margin-bottom: 0;
    font-weight: bold;
}

.bb-edit-panel-img, .bb-slider img {
    width: 100%;
    border-radius: 3px;
    max-width: 600px;
    margin-bottom: 0.5rem;
}

.bb-panel-data iframe {
    max-width: 100%!important;
}

.bb-panel-count-label {
    color: var(--secondary-color);
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 3px;
    width: 53px;
    display: flex;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
    margin-right: auto;
    display: none;
}

/* bb panel link type styling */
.bb-links a {color: var(--secondary-color);}

/* slider / carousel */
.bb-slider .bb-links-data {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0.25rem;
}

.bb-slider .bb-panel-data {
    display: flex;
    flex-direction: column;
    position: relative;
}

.bb-slider .bb-links-cont {
    display: flex;
    overflow-x: auto;
}

.bb-slider .bb-links-data a {
    font-size: 0.7rem;
    font-weight: bold;
    text-decoration: none;
}

.bb-slider .bb-links-data img {
    max-width: 400px!important;
}

/* images */
.bb-links-data img {
    max-width:100%;
}

/* beebot learn */
.bb-bot-learn p:last-child {
    margin-bottom: 0;
    border: 1px solid #ddd;
    padding: 0.5rem 0.3rem 0.2rem 0.5rem;
    background: white;
    border-radius: 3px;
}

/* button stack */
.bb-button-stack .bb-links-data {
    margin-bottom: 0.5rem;
}

.bb-button-stack .bb-links-data a {
    background: #eee;
    color: #111;
    padding: 0.25rem 0.5rem;
    margin: 0.25rem;
    border-radius: 3px;
    text-decoration: none;
    min-width: 200px;
    display: block;
    text-align: center;
}

/* accordion */
.bb-panel-accordion-head {
    background: #eee;
    border-radius: 3px 3px 0 0;
    border:1px solid #ddd;
    margin: 0;
    padding: 0.25rem;
}

.bb-panel-accordion-head b::before {
    content: "\e313";
    font-family: Material Icons;
    font-size: 1rem;
    margin-right: 0.4rem;
    float: left;
    margin-top: 0.1rem;
}

.bb-panel-accordion-body {
    background: #f9f9f9;
    border-radius: 0 0 3px 3px;
    border: 1px solid #ddd;
    margin: 0;
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 0.5rem;
    border-top: 0px;
}

.bb-panel-accordion-body p {
    margin: 0;
    padding: 0;
    background: none;
}

/* icon box */
.bb-icon-box .bb-links-data {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

span.bb-icon-box {
    font-size:3rem;
}

/* fading banner */
.bb-banner-text-cont {
    padding: 1rem;
    color: #fff;
}

.bb-panel-banner-text a {
    color: #fff;
}
.bb-panel-banner-head {
    font-weight:bold;
    font-size: 1.5rem;
}

.bb-panel-banner-sub {
    font-size:1rem;
    font-weight:bold;
}

.bb-panel-banner-text {
    max-width:400px;
}

.bb-panel-cta {
    padding: 0.5rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
    border-radius: 3px;
    text-decoration: none;
    min-width: 150px;
    text-align: center;
    float: left;
    background: #eee;
    color: var(--secondary-color);
}

.bb-fading-banner .bb-links {
    margin-bottom: 0.5rem;
    border-radius: 3px;
}

.bb-banner-text-cont {
    width:100%;
}

.bb-banner-img {
    width:100%;
    max-width: 500px!important;
    border-radius: 3px;
}

/* media panel */
.bb-media-panel .bb-links-data {
    display: flex;
    align-items: center;
    background: #eee;
    margin-bottom: 0.25rem;
    padding: 0.5rem!important;
    border-radius: 3px;
}

.bb-media-panel .bb-links-data img {
    max-width: 40px;
    margin-right: 0.25rem;
}

.bb-media-panel .bb-links-data a {
    font-size: 0.9rem;
    text-decoration: none;
}
.bb-media-panel .bb-links-data::after {
    font-family: "Material Icons";
    content: "\e5cc";
    font-size: 20px;
    color: #111;
    margin-left: auto;
    margin-right: 0.25rem;
}

.bb-media-panel .bb-panel-links-edit, .bb-launcher .bb-panel-links-edit, .bb-button-stack .bb-panel-links-edit {
    bottom:0;
    right:0;
}


/* if many cols must be portrait style banner */
.bb-col-cont .bb-col .bb-fading-banner .bb-links-data {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
}

.bb-col-cont .bb-col .bb-fading-banner img {
    margin-bottom: 0.5rem;
}

/* if only one col then must be a full with banner */
.bb-col-cont .bb-col:only-child .bb-fading-banner .bb-links-data {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
}

.bb-col-cont .bb-col:only-child .bb-fading-banner img {
    margin-bottom: 0;
}

/* start chat */
.bb-start-chat p {
    border: 1px solid #ddd;
    margin: 0.5rem!important;
    padding: 0.5rem;
    border-radius: 3px;
}

/* launcher */
.bb-launcher .bb-links-cont {
    display: flex;
    flex-direction: column;
}
.bb-launcher .bb-links a::before {
    content: "\e89e";
    font-family: Material Icons;
    font-size: 1rem;
    margin-right: 0.4rem;
    float: left;
    margin-top: 0.1rem;
}

/* delete */
.bb-edit-panel-delete {
    margin-left: auto;
}

.bb-edit-panel-delete:hover {
    cursor: pointer;
    opacity:0.7;
}

/* edit modal */
#editContentModal .modal-dialog {
    max-width:800px;
}

/* panel type header icons */
.bb-panel-info::before {
    font-family: Material Icons;
    color: white;
    font-size: 0.9rem;
    margin-right: 0.4rem;
    float: left;
    margin-top: -0.1rem;
}

.bb-panel-title-cont {
    align-items: center;
}

.bb-panel-title-cont .bb-start-chat::before {
    content: "\e625";
}

.bb-panel-title-cont .bb-fading-banner::before {
    content: "\e413";
}

.bb-panel-title-cont .bb-icon-box::before {
    content: "\e838";
}

.bb-panel-title-cont .bb-html::before {
    content: "\e86f";
}

.bb-panel-title-cont .bb-launcher::before {
    content: "\e89e";
}

.bb-panel-title-cont .bb-accordion::before {
    content: "\f101";
}

.bb-panel-title-cont .bb-slider::before {
    content: "\e8eb";
}

.bb-panel-title-cont .bb-media-panel::before {
    content: "\e3f4";
}

.bb-panel-title-cont .bb-button-stack::before {
    content: "\e8e9";
}

.bb-panel-title-cont .bb-bot-learn::before {
    content: "\f0e2";
}

.bb-panel-title-cont .bb-video::before {
    content: "\e1c4";
}


/* off canvas forms */
.offcanvas-end {
    z-index: 1010;
}

#dynamicEditor #bb-html {
    min-height: 290px;
    font-family: 'Courier New', Courier, monospace;
}

#dynamicEditor label {
    font-size:1rem;
    font-weight: bold;
}

#dynamicEditor .form-check, #dynamicEditor .flex-column {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem!important;
    padding-bottom: 0.5rem;
}

#dynamicEditor .flex-column:last-child, #dynamicEditor .form-check {
    border:none;
}

#dynamicEditor .form-select {
    margin-left: 0!important;
}

#dynamicEditor .bb-row, .bb-col-edit-cont, #dynamicEditor .bb-panel-edit-cont, #dynamicEditor .bb-links {
    padding: 1rem 0.5rem 0.5rem 0.5rem!important;
    background: #f9f9f9;
    margin: 0 0 0.5rem!important;
    border: none;
    border-radius: 0 3px 3px 3px;
}

#dynamicEditor label, #dynamicEditor .form-text {
    margin: 0;
}

#dynamicEditor .links-header-label {
    display: flex;
    align-items: center;
    background: #eee;
    padding: 0.25rem;
}

#dynamicEditor .form-text {
    font-size:0.8rem;
}

.offcanvas .btn-close {
    z-index: 999;
    position: absolute;
    right: 0px;
}

.offcanvas .btn-primary:last-child {
    position: fixed;
    bottom:2rem;
    width:315px;

}

#dynamicEditor .bb-check .form-select {
    width: auto;
}

.bb-row-edit-cont {width: 100%;}

.bb-add-row i::before {margin: 0px!important;}

/* editing save */
#de-save-message p {
    position: absolute;
    top: 10px;
    width: 314px;
    text-align: center;
    padding: 1rem;
    border-radius: 5px;
    left: 0;
    right: 0;
    margin: auto;
}

/* modal */
.bb-widgets {
    max-height: 500px;
    overflow: auto;
}

.bb-panel-links-edit {
    position: absolute;
    bottom: -9px;
    right: -8px;
    background:var(--primary-color);
    padding: 0.5rem 0.3rem 0.6rem 0.8rem;
    border-radius: 11px 0px 0px;
}

.bb-panel-links-edit:hover {
    background:var(--secondary-color);
    opacity: 0.9;
}

.bb-panel-links-edit .bi::before {
    color: #fff!important;
}

.panel-type {
    border: 1px solid #bbb;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ccc;
}

.panel-type p {
    margin-bottom: 0.5rem!important;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: bold;
}

/* editing content media */
#editContentModal .modal-body {
    min-height: 400px;
    overflow: auto;
    max-height: 80vh;
}

#dynamicEditor .accordion-body {
    position: absolute;
    right: 20px;
    background: none!important;
}

.modal-dialog .modal-content #dynamicEditor .bb-links {
    margin-bottom: 0px!important;
}

.bb-input-fields {
    position: relative;
}

.bb-close {
    display: none;
}

.bb-open {
    display: block;
}

/* mobile styles */
@media only screen and (max-width: 700px) {
    .bb-page-edit-options-container .d-flex input {min-width: auto;}
}
