.bb-module-card p, .bb-module-card div {
    color: var(--bb-secondary);
}

.bb-card-header {
    background: linear-gradient(var(--garibbean-current), var(--gunmetal)) !important;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px 10px 0 0;
}

.bb-header-icons svg {
    color: #fff;
    border: 2px solid #fff;
    border-radius: 269px;
    margin: 1rem;
    font-size: 4rem;
    padding: .5rem;
}

.bb-app-icon:hover .bb-header-icons svg {
    color: var(--bb-primary);
    border-color: var(--bb-primary);
}

.MuiCardHeader-content span {
    font-size: 1rem;
}

.MuiCardActions-root {
    padding: 0.75rem 1rem !important;
    background: #eee;
}