/* THEME EDITOR */
#root form.bb-drop-form-group-padding .form-group .field-object {
    padding:0!important;
    border: 0!important;
}

form.bb-page-editor-form textarea, form.bb-page-editor-form .MuiOutlinedInput-root {
    font-family: 'Courier New', Courier, monospace!important;
    font-weight: 600;
    background: #fff;
}

form.bb-page-editor-form h5 {
    font-size: 1.2rem;
    font-weight: bold;
}

form.bb-page-editor-form h6 {
    font-size: 0.8rem;
    font-style: italic;
    color: #888;
    padding-left: 0.5rem;
}

form.bb-page-editor-form h6::before {
    content: "* ";
}

form.bb-page-editor-form .form-group .field-object {
    padding: 1rem !important;
    background: var(--bb-soft-grey)!important;
    border-radius: 5px;
    border:1px solid #eee;
}

form.bb-page-editor-form .form-group .field-object input {
    background: #fff;
}
