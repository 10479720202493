/* PATHWAYS */
form.bb-pathway-form div .form-group .field-object {
    padding: 1.5rem!important;
}

.bb-pathways-initial-form input {
    border-radius: 8px;
    background: #fff!important;
}

.bb-pathways-initial-form .MuiSelect-select {
    background: #fff!important;
}

.custom-class-title1 h5, .custom-class-title2 h5  {
    font-size: 1rem!important;
    font-weight: bold;
}