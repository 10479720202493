/* HEADER ----------------------------------------------------------  */
header.MuiPaper-root {
    box-shadow: var(--bb-ui-shadow)!important;
    border-radius:0px!important;
    width:100%!important;
    background: var(--bb-secondary)!important;
}

header .user-name {
    text-transform: capitalize;
    max-width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff!important;
}

.bb-mob-menu svg {
    color: #fff!important;
}

header button:hover {
    background-color: var(--gunmetal) !important;
}

/* SEARCH */
.bb-top-bar-search {
    margin-left: auto !important;
    width: auto !important;
    text-align: right;
}

.bb-top-bar-search-icon{
    left:-53px;
}

.user-profile {
    min-width: 180px;
    justify-content: end;
}

header a img {
    padding:0 0.75rem;
}

.bb-text-decoration-none {
    text-decoration: none!important;
}

/* SEARCH */
.css-1b3ikr0-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
    padding-left: 0.5rem!important;
}

/* MAIN BODY ---------------------------------------------------------*/
.main-viewport {
    padding: 1rem 3rem 3rem 3rem!important;
    margin-top: 3rem!important;
    min-height: calc(100vh - 180px) !important;
    margin-left: 16rem!important;
    max-height: 100%!important;
    overflow: auto;
}

/* TITLE BARS */
.bb-title-bar {
    display: flex;
    text-transform: capitalize;
    align-items: center;
}

.bb-title-info {
    margin-bottom: 1rem;
    background: var(--bb-soft-green)!important;
    border: 1px solid var(--bb-primary);
    padding: 0 0 0 0.5rem;
}

.bb-title-info .MuiAlert-icon {
    color: var(--bb-primary)!important;
}

.customer-portal {
    padding: 0 !important;
    max-width: 100% !important;
    height: 100vh;
}

.main-content {
    min-height: calc(100vh - 76px);
    align-items: center;
}

@media (min-width: 1200px) {
    .customer-portal {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 900px) {
    .sidebar-container {
        width: 0 !important;
        overflow: hidden;
    }

    /* this toggles nav but uses a dynamic class */
    .css-196jqjz {
        width: auto !important;
    }

    .MuiDrawer-docked div {
        visibility: visible !important;
        transform: none !important
    }

    body .main-viewport {
        margin-left: 0 !important;
    }
}

