:root {
    --bb-primary: #11a681;
    --bb-secondary: #25454d;
    --bb-softHover: #f6f9ff;
    --bb-soft-green: #E2F5F2;
    --bb-soft-blue: #D8F2FF;
    --bb-soft-grey: #F6F5F8;
    --bb-soft-peach: #FFE1DE;
    --bb-mid-blue: #79B8D9;
    --garibbean-current: #265b5e;
    --gunmetal: #1b3440;
    --light-blue: #96ffff;
    --bb-box-shad: #ddd;
    --bb-ui-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    --bb-radius: 5px;
}

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: normal;
}

a {
    color: var(--bb-primary);
}

.bb-primary {
    color: var(--bb-primary) !important;
}

.bb-secondary {
    color: var(--bb-secondary);
}

/* BBOT UI KIT */
.img-responsive {
    width: 100%;
}

.bb-box {
    border-radius: var(--bb-radius);
    box-shadow: 0px 0px 3px var(--bb-box-shad);
    background-color: #fff !important;
    margin-bottom: 2rem !important;
    display: flex;
}

.bb-list {
    font-weight: bold;
    margin-left: 0;
    padding-left: 1rem;
}

.bb-text-center {
    text-align: center;
}

.bb-hide {
    display: none !important;
}

.bb-o-v {
    overflow: visible !important;
}

/* MARGIN */
.bb-mb-0 {
    margin-bottom: 0 !important;
}

.bb-m1 {
    margin: .5rem;
}

.bb-mb-1 {
    margin-bottom: .5rem !important;
}

.bb-mb-2 {
    margin-bottom: 1rem !important;
}

.bb-mb-3 {
    margin-bottom: 1.5rem !important;
}

.bb-mb-4 {
    margin-bottom: 2rem !important;
}

.bb-mt-0 {
    margin-top: 0 !important;
}

.bb-mt-1 {
    margin-top: .5rem !important;
}

.bb-mt-2 {
    margin-top: 1rem !important;
}

.bb-mt-3 {
    margin-top: 1.5rem !important
}

.bb-mt-4 {
    margin-top: 2rem !important
}

.bb-m0 {
    margin: 0 !important;
}

.bb-mr-1 {
    margin-right: .5rem !important;
}

.bb-mr-2 {
    margin-right: 1rem !important;
}

.bb-ml-1 {
    margin-left: .5rem !important;
}

.bb-ml-2 {
    margin-left: 1rem !important;
}

/* PADDING */
.bb-p0 {
    padding: 0 !important;
}

.bb-p-1 {
    padding: .5rem !important
}

.bb-p-2 {
    padding: 1rem !important
}

.bb-p-3 {
    padding: 1.5rem !important
}

.bb-pl-1 {
    padding-left: .5rem !important;
}

.bb-pr-1 {
    padding-right: .5rem !important;
}

/* FONT SIZE */
.fontSize1 {
    font-size: 1rem !important;
}

.fontSize2 {
    font-size: 2rem !important;
}

.fontSize3 {
    font-size: 3rem !important;
}

/* FLEX */
.bb-flex {
    display: flex;
}

.bb-flex-wrap {
    flex-wrap: wrap;
}

.bb-flex-column {
    flex-direction: column !important;
}

.bb-ml-auto {
    margin-left: auto !important;
}

.bb-align-items-center {
    align-items: center;
}

.bb-justify-content-center {
    justify-content: center;
}

.bb-flex-end {
    justify-content: end;
}

.bb-flex-start {
    justify-content: start;
}

.bb-flex-fill {
    width: -webkit-fill-available;
}

.bb-border-radius,
.bb-rounded {
    border-radius: var(--bb-radius);
}

/* UI BOX */
.bb-ui-box {
    background: #fff;
    padding: 1rem !important;
    box-shadow: 0px 0px 3px #ddd;
    margin: 1rem 0 !important;
    border-radius: var(--bb-radius);
    width: 100% !IMPORTANT;
    min-width: 100% !IMPORTANT;
    display: flex !important;
    box-sizing: border-box;
    float: left;
}

.bb-ui-box-alt {
    background: #fff;
    padding: 1rem !important;
    box-shadow: 0px 0px 3px #ddd;
    margin: 0 0 1rem 0 !important;
    border-radius: var(--bb-radius);
    width: 100% !IMPORTANT;
    min-width: 100% !IMPORTANT;
    display: flex !important;
    box-sizing: border-box;
    float: left;
}

.bb-min-height-3 {
    min-height: 3rem;
}

.bb-min-height-5 {
    min-height: 5rem;
}

.bb-tac {
    text-align: center;
}

.bb-tal {
    text-align: left;
}

.bb-width-100 {
    width: 100%;
}

.bb-flex-1 {
    flex: 1;
}

.bb-hide {
    display: none;
}

.bb-small-text {
    font-size: .8rem;
}

/* BUTTON */
.bb-primary-button,
.bpb {
    background: var(--bb-primary) !important;
    color: #fff !important;
}

.bb-primary-button:hover,
.bpb:hover {
    opacity: .8;
}

.bb-error-button {
    background: none !important;
    color: #d9534f !important;
}

/* DASH ---------------------*/
.bb-dash-container .MuiGrid-item div {
    box-shadow: none !important;
    text-align: left !important;
}

.bb-refresh-bar {
    background: aliceblue;
    border: 1px solid rgb(111, 204, 244) !important;
    border-radius: var(--bb-radius);
}

/* H TAGS & P ------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
p {
    margin-bottom: .5rem !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: bold !important;
}

p {
    line-height: 1.3rem;
}

.bb-soft-grey-bg {
    background: #eee;
}

/* BREADCRUMB */
.bb-breadcrumb {
    margin: .4rem 0 .7rem 0 !important;
}

.bb-breadcrumb a {
    background: #fff;
    padding: .1rem .5rem;
    border: 1px solid rgba(17, 166, 129, .5);
    border-radius: var(--bb-radius);
    text-decoration: none;
}

.bb-breadcrumb a:hover {
    opacity: .7;
}

.bb-crumb-text {
    padding: .1rem .5rem !important;
    border: 1px solid #ddd;
    border-radius: var(--bb-radius);
}

.MuiBreadcrumbs-separator {
    margin: 0 .2rem !important;
}

.bb-app-button-loader {
    margin-top: -40px;
}

.bb-app-button-loader span {
    width: 20px !important;
    height: 20px !important;
}

/* authentication splash screen */
.bb-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bb-loader p {
    font-size: 1.5rem;
    margin-top: 5rem;
}

/* MODAL OVER RIDE -------------------------------------------------  */
.bb-global-modal {
    min-width: 500px !important;
}

.bb-error-dialog-header {
    background: #d9534f !important;
    color: #fff !important;
    justify-content: center;
}

.bbot-dialog-title {
    color: white;
    background: var(--bb-secondary) !important;
    text-align: center;
    font-weight: bold !important;
}

.bbot-dialog-title p {
    font-size: 1.2rem !important;
    font-weight: bold;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.bbot-dialog-content {
    padding: 1rem 2rem !important;
    text-align: center;
    LINE-HEIGHT: 26PX;
}

.bbot-dialog-actions {
    justify-content: space-between !important;
    background: #eee !important;
}

/* DATA PAGES PARTNER DIRECTORY */
.MuiDataGrid-cell--editing {
    background: var(--bb-soft-green) !important;
}

@media only screen and (min-width: 1600px) {
    .bb-media-card-cont {
        max-width: 12% !important;
    }
}

@media only screen and (min-width: 2000px) {
    .bb-media-card-cont {
        max-width: 10% !important;
    }
}

.bot-card-actions label {
    margin-left: 0.35rem;
}

.bb-media-card {
    box-shadow: 0 2px 3px 0px #eee !important;
}

.bb-media-card .MuiBox-root {
    min-height: 110px;
    display: block;
    margin: auto;
    display: flex;
    background: #eee;
}

.bb-card-footer {
    background: var(--bb-soft-grey);
}

.bb-media-card .MuiBox-root img {
    margin: auto;
    max-height: 112px;
}

.bb-media-card:hover {
    cursor: pointer;
}

.bb-media-item-delete {
    position: absolute;
    right: 0;
    bottom: 0;
    min-height: auto !important;
    background: none !important;
}

.bb-media-item-info {
    position: relative;
}

.bb-drag-drop {
    background: #eee !important;
    box-shadow: none !important;
    border: 1px dashed #ddd !important;
    padding: 1rem !important;
    display: flex;
    align-items: center;
}

.bb-drag-drop:hover {
    background: var(--bb-soft-grey) !important;
    cursor: pointer;
}

.bb-drag-input {
    padding: 2rem 4rem;
    width: 100%;
}

.bb-drag-input button {
    margin-bottom: 1rem;
}

.bbot-dialog-content svg {
    display: none !important;
}

.bb-gallery-large-img {
    border-radius: var(--bb-radius);
    margin-bottom: 1rem;
}
/* DD OVER RIDES END */

/* dash */
.stat-graph {
    background: white;
    border-radius: var(--bb-radius);
    padding: 30px;
}

/* cards */
.stat-card {
    border: none;
    background: var(--bb-secondary) !important;
}

.stat-card .stat-card-title {
    margin: 0;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0em;
    font-size: 14px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}

.stat-card .stat-card-value {
    text-align: center;
    font-weight: bold;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    margin: auto;
    font-size: max(35px, 2cqw) !important;
}

ul.MuiMenu-list {
    min-width: 120px;
    padding: 10px;
}

.MuiCardHeader-root button {
    color: white;
}

.MuiCardHeader-content {
    overflow: hidden;
}

/* CARDS / TILES DIALOGS */
.bot-card-header,
.MuiCardHeader-root,
.signin-dialog-title,
.rpa-card-header,
.bb-icon-bg {
    color: white;
    background: var(--bb-secondary)
}

.bot-progress {
    height: 6px !important;
}

.rpa-card-actions {
    background: #f6f6f6;
    padding: 1rem !important;
}

.signin-dialog-title {
    text-align: center;
    font-size: 22px !important;
    font-weight: bold !important;
    margin-bottom: 0px !important;
}

.signin-dialog-content {
    padding: 30px 70PX;
    text-align: center;
    LINE-HEIGHT: 26PX;
    margin-bottom: 0px !important;
}

.dialog-actions {
    justify-content: space-between !important;
    background: #eee !important;
}

/* app loader css */
@property --angle {
    syntax: "<angle>";
    inherits: false;
    initial-value: 0deg;
}

.bb-app-loader {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid var(--bb-primary);
    border-radius: 50%;
    left: 49%;
    right: 50%;
    top: -70px;
}

.bb-app-loader i {
    position: absolute;
    inset: 0;
    background: var(--bb-primary);
    border-radius: 50%;
    translate: calc(sin(var(--angle)) * 50px) calc(cos(var(--angle)) * 50px);
    animation: rotate 3s infinite ease-in-out;
}

@keyframes rotate {
    0% {
        --angle: 360deg;
    }

    100% {
        --angle: 0deg;
    }
}

/* dash table */
.bot-table {
    border: 1px solid #ddd;
}

.bot-table-header {
    background: #f6f6f6;
    border-bottom: 1px solid #ddd;
}

.bot-table thead th {
    border-bottom: 1px solid #ddd;
    font-size: 13px;
}

.bot-table tr:nth-child(even) {
    background: var(--bb-soft-grey);
}

.bot-table tr td,
.MuiTable-root tr th {
    font-size: 13px !important;
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 99px !important;
}

/* Track */
*::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 99px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: #999;
}

/* Data grid table */
.MuiDataGrid-main {
    background: #fff;
    font-size: 0.8rem;
    border-radius: 5px 5px 0 0;
    margin: 10px;
}

.MuiDataGrid-footerContainer {
    background: #fff;
    border-radius: 0 0 5px 5px;
}

.MuiDataGrid-row:nth-child(even) {
    background: #f5f5f5;
}

.MuiDataGrid-columnHeaders,
.MuiDataGrid-container--top,
.MuiDataGrid-container--top [role=row] {
    background: var(--bb-secondary) !important;
    color: #fff !important;
}

.MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root {
    color: #fff !important;
}

.MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
    color: #fff !important;
}

.MuiDataGrid-columnHeader .MuiDataGrid-menuIconButton {
    color: #fff !important;
}

.MuiDataGrid-selectedRowCount {
    font-size: 0.8rem !important;
}

.MuiTablePagination-selectLabel {
    font-size: 0.8rem !important;
}

.MuiTablePagination-input {
    font-size: 0.8rem !important;
}

.MuiTablePagination-displayedRows {
    font-size: 0.8rem !important;
}

/* APP STAT CARDS */
.bb-app-icon-description {
    padding: 0.5rem;
    text-align: center;
    font-size: 1rem;
    margin: 0rem;
    background: #E2F5F2;
    border-radius: var(--bb-radius);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 160px;
}

.bb-app-icon-stats {
    padding: 0.5rem;
}

.bb-app-icons .MuiCardActions-root> :not(style)~ :not(style) {
    margin-left: auto !important;
}

.bb-app-icons .MuiCardActions-root button {
    background: var(--bb-primary);
    color: #fff;
}

/* TOAST */
.Toastify--animate-icon svg {
    fill: var(--bb-primary) !important;
}

.Toastify__progress-bar--success {
    background: var(--bb-primary) !important;
}

/* GLOBAL TABLE STYLES */
.bb-global-table {
    border: 1px solid #eee;
    box-shadow: none !important;
}

.bb-global-table thead {
    background: var(--bb-secondary);
}

.bb-global-table thead tr th {
    color: #fff;
    font-size: .9rem !important;
}

.bb-global-table tr td,
.bb-global-table tr th {
    border-bottom: 1px solid #ddd;
}

.bb-global-table tr td {
    padding: 0;
}

.bb-global-table tr:nth-child(even) {
    background: #f6f6f6;
}

.bb-global-table .MuiTablePagination-select,
.bb-global-table-footer .MuiTablePagination-input {
    border: 1px solid #eee;
    border-radius: var(--bb-radius);
    background: #eee;
}

.bb-global-table-footer .MuiTablePagination-input,
.bb-global-table-footer .MuiTablePagination-select {
    border-radius: var(--bb-radius);
    background: #fff;
}

.bb-global-table-footer {
    background: #eee;
}

/* ANIMATION */
.bounce {
    animation: bounce 2s ease;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

/* FADE IN UP WITH DELAY -----------------------------------------------*/
@keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0, 40px, 0)
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.animated {
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-duration: 1s;
    animation-fill-mode: bothh
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;}
.fadeInUp1 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 100ms;}
.fadeInUp2 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 200ms;}
.fadeInUp3 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 300ms;}
.fadeInUp4 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 400ms;}
.fadeInUp5 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 500ms;}
.fadeInUp6 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 600ms;}
.fadeInUp7 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 700ms;}
.fadeInUp8 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 800ms;}
.fadeInUp9 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 900ms;}
.fadeInUp10 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1000ms;}
.fadeInUp11 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1100ms;}
.fadeInUp12 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1200ms;}
.fadeInUp13 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1300ms;}
.fadeInUp14 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1400ms;}
.fadeInUp15 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1500ms;}
.fadeInUp16 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1600ms;}
.fadeInUp17 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1700ms;}
.fadeInUp18 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1800ms;}
.fadeInUp19 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 1900ms;}
.fadeInUp20 {opacity: 0;-webkit-animation-name: fadeInUp;animation-name: fadeInUp;animation-delay: 2000ms;}

/* --------------- END FADE IN UP -----------*/

/* misc */
.bb-misc-ui-helper {
    margin-left: 16px !important;
    width: calc(100% - 16px) !important;
    min-width: calc(100% - 16px) !important;
}

.bb-user-card-body {
    min-height: 250px;
}

.MuiCardHeader-title {
    text-transform: capitalize;
}

.MuiCircularProgress-root {
    margin: auto;
    margin-top: 3rem;
}

/* mobile styles */
@media (max-width:600px) {

    .bb-global-modal {
        min-width: 100% !important;
    }

    .bb-pe-col-title .MuiCardHeader-action {
        margin: auto;
    }

    .bbVideo {
        width: 100%;
    }

    .bb-pe-col-title,
    .bb-social-box .bb-flex,
    .page-edit-buttons,
    .bb-column-container,
    .bb-contact {
        flex-direction: column;
    }

    .bb-op-share {
        width: 100% !important;
        max-width: 100%;
    }

    .page-edit-buttons button {
        width: 100%;
        margin: 1rem .25rem;
    }

    .page-edit-buttons a {
        display: contents;
    }

    /* search */
    .bb-top-bar-search .MuiInputBase-input {
        min-width: 70px;
    }

    .bb-top-bar-search-icon {
        display: none !important;
        margin-left: 0.5rem;
    }

    .user-profile {
        min-width: auto;
    }

    .queue-grid {
        min-width: 30vw;
    }

    /* Top toolbar */
    .user-profile svg {
        font-size: 2rem !important;
    }

    .user-profile .user-name {
        display: none;
    }

    main {
        width: 100%;
    }

    body .main-viewport {
        padding: 0.5rem 1rem 1rem 1rem !important;
    }

    .chat-sidebar {
        max-width: 100%;
    }

    .chat-info {
        max-width: 100% !important;
    }

    .bb-apps .css-1oqqzyl-MuiContainer-root {
        padding-right: 0px;
    }

}

.bb-link-white {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}