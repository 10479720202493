/* DEPLOYMENTS PAGE */
.bb-tabs-cont #tabpanel-0, .bb-tabs-cont #tabpanel-1 {
    margin: 0;
    padding: 0;
    background: #eee;
    border-radius: 0 0 5px 5px;
}

.bb-deployment-sub-items tr td {
    font-size: 13px;
}


.bb-deployment-sub-items tr:nth-child(even) {
    background: #fff;
}

.bb-deployment-sub-items tr, .bb-deployment-sub-items thead  {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}