/* BB SETTINGS / APP FEATURES */
.bb-app-settings {
    box-shadow: none!important;
    border:1px solid #ddd;
    margin-bottom: -1px!important;
    text-align: left!important;
    background: #f6f6f6!important;
}

.bb-app-settings input, .bb-app-settings .MuiSelect-select, .bb-app-settings .MuiInputBase-input {
    background: #fff!important;
}

.bb-app-features:last-of-type {
    box-shadow: none;
    border:1px solid #ddd;
}

.bb-app-features .form-group div.MuiGrid-item:not(:first-child) {
    padding: 0;
    margin:0;
    margin-bottom: -1px!important;
    padding-left: 0.5rem;
    padding-bottom: .5rem;
    margin-left: 16px;
    border:1px solid #eee;
}

.bb-app-features .form-group div.MuiGrid-item:nth-child(odd) {
    background: var(--bb-soft-grey);
}

.bb-app-features .form-group div.MuiGrid-item:nth-child(even) {
    background: #fff;
}

.bb-app-features {
    margin: 0px!important;
    text-align: left!important;
    background: #f6f6f6!important;
}

.bb-app-features h6 {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0;
    padding-left: 13px;
    padding-top: .4rem;
    padding-bottom: 6px;
    margin-bottom: -10px;
}

.bb-app-features label .MuiCheckbox-root {
    padding-left: 1rem!important;
    margin-left: .3rem;
}

.bb-app-features label span {
    color: #666;
}

.bb-app-settings .MuiAccordionSummary-content h4, .bb-app-features .MuiAccordionSummary-content h4 {
    margin-bottom: 0!important;
    padding-bottom: 0!important;
}

.bb-settings-container .MuiSelect-select {
    background: #fff;
}

/* Adding Accordion Expand Styles */
.Mui-expanded .Mui-expanded {
    background: var(--bb-secondary)!important;
    color: #fff;
}

.Mui-expanded .Mui-expanded svg {
    color: #fff;
}