/* live agent */
main .live-agent-container {
    max-height: 1200px;
    height: calc(100vh - 120px);
    margin-top: 1rem;
}

.MuiDialogContent-root,
.MuiDialogContent-dividers,
.css-qwcm6i-MuiDialogContent-root {
    border: 0px !important;
}

main .chat-list-sidebar {
    background: #eee;
    border-radius: 5px 0 0 5px;
    flex: 0.7;
}

/* chat list - Chat details */
.chat-sidebar {
    max-width: 300px;
    background: #f6f6f6 !important;
    border-radius: 5px 0 0 5px;
    padding: 10px !important;
}

/* chat info panel */
.chat-info {
    background: #eee;
    flex: 10 !important;
    border-radius: 0 0px 5px 0;
    max-width: 350px;
    min-width: 200px;
}

/* chat send button */
.css-15mydm5 {
    background: var(--bb-primary);
    border-radius: var(--bb-radius);
    margin-left: 0.5rem;
}

/* chat panel */
.css-1dfh6rl {
    height: calc(100vh - 152px);
}

.bb-chat-input {
    background: #f6f6f6;
    padding: .5rem;
    border: 1px solid #ddd;
    border-radius: var(--bb-radius);
}

.bb-chat-input-go {
    min-width: 56px;
    background: var(--bb-primary);
    border-radius: 10px;
    margin-left: .5rem;
    color: #fff;
}

.live-chat-preview {
    background: #fff;
}

.bb-chat-bubbles {
    box-shadow: 0px !important;
}

.live-agent-sidebar-buttons {
    display: flex;
    flex-direction: column;

}

.live-agent-sidebar-buttons .MuiBadge-colorSecondary {
    background: red;
}

.queue-dialog-title {
    color: white;
    background: var(--bb-secondary) !important;
    text-align: center;
    font-size: 22px !important;
    font-weight: bold !important;
    display: flex;
    justify-content: center;
}

.queue-item {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.queue-item-card-title {
    color: white;
    text-align: center;
}

.queue-item-info {
    color: white;
}

.queue-item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.queue-item-text {
    flex-grow: 1;
}

.queue-select-confirm-prompt {
    color: white;
    text-align: center;
    background: var(--bb-secondary) !important;
    text-align: center;
}

.queue-select-confirm-content {
    padding: 2em 1em;
    text-align: center;
    LINE-HEIGHT: 2em;
}

.queue-grid {
    min-width: 540px;
}

.asset-folder-header {
    color: white;
    background: var(--bb-secondary) !important;
}

.asset-file-box {
    display: flex;
    justify-content: space-between;
    max-width: max(70vw, 600px);
    border-radius: initial !important;
    border-bottom: solid 1px #efefef;
}

.background-expansion {
    background: #eee !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
