.textEditor-width-100 {
	width: 100%!important;
}

.status-container {
    display: flex;
    justify-content: flex-start!important;
    align-items: center!important;
}

@media (max-width: 768px) {
    .status-container {
        flex-direction: column!important;
        align-items: center!important;
        margin-top: 0px!important;
    }
}

@media (max-width: 1000px) {
    .form-buttons-container,
    .form-buttons {
        flex-direction: column!important;
        align-items: flex-start!important;
    }

    .form-buttons-container {
        margin-bottom: 0px!important;
        width: 100%!important;
    }

    .form-buttons > * {
        margin-bottom: 10px!important;
        width: 100%!important;
    }
}