/* GALLERY ---------------------*/
.bb-media-label {
    padding-bottom: 0!important;
    margin-bottom: 0!important;
}

.bb-med-lib-header {
    text-transform: capitalize;
    margin-bottom: 0px!important;
    padding-bottom: 0px!important;
    display: flex;
    align-items: center;
}

#list-results {
    width: 100%;
}

.bb-folder-list {
    margin-bottom: 1.5rem;
    width:100%;
}

.bb-folder-list .MuiPaper-elevation {
    box-shadow: 0 2px 8px #ddd!important;
    border:none;
    margin-left: 0.5rem;
}

.bb-folder-list .asset-folder-header {
    background-color: #fff!important;
    color: #1b3240;
    padding: 0.8rem;
}

.bb-folder-up {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.bb-folder-up button {
    background: var(--bb-soft-grey)!important;
}

.bb-folder-up .asset-folder-header {
    margin-top: 0.5rem;
    padding: 0rem 0.9rem;
    padding-bottom: 1rem;
    background: var(--bb-soft-grey) !important;
}

.bb-add-folder {
    margin-top: 0.5rem !important;
    margin-left: 0.2rem !important;
}

.bb-media-item-info {
    overflow-wrap: break-word;
}