/* PAGE EDITOR - PAGES LIST */
.bb-page-save-loader {
    position: relative;
    left:0;
    right:0;
    bottom:0;
    top:0;
}

.bb-pages-list {
    text-transform: capitalize;
    width: 100%;
    display: flex;
    padding: 1rem;
    font-weight: bold;
}

.bb-page-list-cont:not(:first-child) {
    border-bottom: 1px solid #eee;
}

.bb-page-list-cont {
    border-top: 1px solid #eee;
}

.bb-page-list-icon {
    color: var(--bb-secondary);
}

/* PAGE EDITOR GLOBAL */
.bb-pe-modal-form .MuiPaper-elevation2  {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 1px #ddd;
}

/* up / down button target */
.bb-pe-modal-form button.MuiIconButton-sizeSmall {
    border: 2px solid #111;
    margin: .2rem;
    background: #ddd;
    width: 2rem;
    height: 2rem;
}

.bb-pe-modal-form button.MuiIconButton-sizeSmall:hover {
    opacity: .7;
} 

.bb-pe-modal-form .MuiPaper-elevation2 .MuiBox-root:first-child {
    background: #fff;
    margin: 0!important;
}

.bb-pe-modal-form .MuiPaper-elevation2 .MuiBox-root .form-group .MuiTypography-h5 {
    background: var(--bb-soft-grey);
    padding: .5rem;
    margin-bottom: 0 !important;
    font-size: 1rem;
}

.bb-header-with-icon {
    text-align: left;
    margin: 0 !important;
    padding: 0;
}

.bb-header-icon {
    width: 20px!important;
    margin-left: .5rem;
    color: var(--bb-primary);
}

.bb-tip-icon {
    width: 20px!important;
    margin-right: .5rem;
    color: var(--bb-mid-blue);
}

.bb-pe-setting {
    color: #111;
}

/* Floating preview button */
.bb-pe-fab {
    position: fixed;
    z-index: 999;
    bottom: 4%;
    right: 1%;
}

.bb-pe-fab button {
    background: var(--bb-primary);
    border-radius: 99px;
    color: #fff;
    height: 65px;
    width: 65px;
    margin-right: .5rem;
}

.bb-pe-fab button:hover {
    background: var(--bb-secondary);
}

.bb-pe-fab button .MuiButton-icon {
    margin: 0!important;
    padding: 0!important;
    width: 20px;
}


/* PAGE EDITOR FORM */
.bb-page-editor-container {
    max-width:2000px!important;
}

.page-edit-buttons button {
    margin-left: 0.5rem;
}

.bb-page-edit-cont .MuiCardHeader-root {
    background: #eee!important;
    color: var(--bb-secondary);
    padding: .2rem .8rem .2rem .2rem !important;
    border-bottom: 1px solid #ccc;
}

.bb-page-edit-cont .MuiCardHeader-root button {
    color: var(--bb-secondary);
    padding: 2px;
    padding-top: 0px;
    margin-top: 4px;
}

.bb-page-edit-cont .MuiCardHeader-root .MuiIconButton-colorError {
    color: #d9534f;
}

.bb-page-edit-cont .MuiCardHeader-root .Mui-disabled {
    opacity: .2;
}

.bb-page-edit-cont #tabpanel-0 {
    background: var(--bb-soft-grey);
    border:1px solid #eee;
}

.bb-page-edit-cont input, .bb-page-edit-cont textarea  {
    background: #fff;
    border-radius: 8px;
}

.bb-page-edit-cont textarea {
    padding: 1.3rem;
}

.bb-pe-titles .MuiTypography-h5 {
    display: flex;
    width: fit-content;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.7rem;
    padding: .4rem;
}

/* form in modal */
.bb-pe-modal-form-media {
    height: calc(70vh - 160px);
    overflow: auto !important;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden !important;
}

.bb-pe-modal-form {
    height: calc(70vh - 128px);
    overflow: auto !important;
    margin-top: 59px;
    background-color: #eee;
    border-top: 1px solid #ddd;
    padding: 2rem;
}

form.bb-pe-modal-form h6 {
    font-size: 0.8rem;
    font-style: italic;
    color: #888;
    padding-left: 0.5rem;
}

form.bb-pe-modal-form  h6 {
    font-size: 0.8rem;
    font-style: italic;
    color: #888;
    padding-left: 0.5rem;
}

form.bb-pe-modal-form h6::before {
    content: "* ";
}

form.bb-pe-modal-form .form-group .field-object {
    background: var(--bb-soft-grey) !important;
    border-radius: 8px;
    margin: 0 !important;
    padding: .75rem !important;
}

form.bb-pe-modal-form .MuiOutlinedInput-root input, form.bb-pe-modal-form .MuiOutlinedInput-root {
    background: #fff!important;
    border-radius: 10px;
}

.bb-pemodal-form .MuiPaper-root {
    box-shadow: none!important;
}

/* page editor accodrion styles */
.bb-accordion-header {
    background: #FFF!important;
    border-radius: 7px!important;
    font-weight: bold!important;
    font-size: 1rem;
}

.bb-accordion-header.Mui-expanded {
    background: var(--bb-secondary)!important;
    color: #fff;
}

.bb-accordion-header.Mui-expanded svg {
    color: #fff;
}

.bb-accordion .Mui-expanded {
    border-radius: 8px 8px 0 0!important;
}

.bb-accordion .MuiAccordionDetails-root {
    background: #f6f6f6;
    padding-top: 1.5rem;
}

/* PAGE EDITOR ROW */
.bb-page-editor-row {
    margin:2rem 0 3rem 0;
    box-shadow: 0 0 6px #bbb
}

.bb-pe-row-title .MuiTypography-h5 {
    background: var(--bb-soft-green);
    border:1px solid var(--bb-primary);
}

.bb-row-subtitle {
    max-width:800px;
    margin: auto;
}

/* PAGE EDITOR COLUMN */
.bb-pe-col {
    overflow: hidden;
    height: auto;
}
.bb-pe-col-content {
    height: 100%;
    --dot-bg: #fff;
    --dot-color: #ddd;
    --dot-size: 1px;
    --dot-space: 4px;
    background: linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space), linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space), var(--dot-color);
}

.bb-pe-col-title .MuiTypography-h5 {
    background: var(--bb-soft-blue);
    border:1px solid var(--bb-mid-blue);
}

.bb-pe-col .bb-move-up {
    transform: rotate(-90deg);
}

.bb-pe-col .bb-move-down {
    transform: rotate(270deg);
}

.bb-single-col {
    min-width: 250px;
}

/* PAGE EDITOR - PANEL */
.bb-pe-panel-title .MuiTypography-h5 {
    background: var(--bb-soft-peach);
    border:1px solid #FF9188;
}

/* PAGE EDITOR - PREVIEW */
.bb-preview-modal {
    border: none!important;
    border-radius: 5px;
    background: #eee!important;
}

.bb-preview-modal .MuiSelect-select {
    background: #fff!important;
}

.bb-dark-icon {
    color: var(--bb-secondary)!important;
    min-width: auto!important;
}

.bb-preview-frame {
    margin: 2rem auto !important;
    text-align: center;
    display: block;
}

.bb-button-loader {
    max-height: 48px;
    display: flex;
    overflow: hidden;
    min-width: 100px;
    max-width: 100px;
    text-align: center;
}

.bb-button-loader .bb-prev-load {
    padding: 0;
    height: 48px;
    margin: 0 auto;
}

/* PAGE EDITOR MODAL */
.bb-edit-page-modal {
    border-radius: 5px;
    width: 60%!important;
    height: 70vh !important;
    max-height: 80vh!important;
    position: relative!important;
    overflow: hidden!important;
    margin: 0;
    padding: 0!important;
}

.bb-edit-page-modal-header, .bb-modal-header {
    background: var(--bb-secondary)!important;
    color: #fff;
}

.bb-edit-page-modal-header button {
    color: #fff!important;
}

.bb-pe-modal-header {
    position: fixed !important;
    top: 0;
    background: var(--bb-secondary)!important;
    color: #fff;
    padding: 1rem;
    left: 0px;
    width: 100%;
    padding: 1rem 1rem 1rem 2rem !important;
    z-index: 9;
}

.bb-pe-modal-header button {
    color: #fff;
}

.bb-pe-modal-footer {
    position: fixed !important;
    bottom: 1px;
    background: #fff!important;
    width: 100%;
    margin: 0 !important;
    left: 0px;
    border-top: 1px solid #ddd;
    padding: 1rem !important;
}

.bb-delete-modal {
    text-align: center;
    padding: 0 !important;
    border: none !important;
    border-radius: 14px;
    overflow: hidden;
}

.bb-med-search {
    background: none!important;
}

.bb-med-search div:first-child {
    background: none;
    border-radius: 6px;
    overflow: hidden;
}

.bb-med-search input {
    background: #fff;
    padding-left: 1rem!important;
}

/* media picker */
.bb-media-thumb-cont {
    height: 53px;
    margin-top: .5rem!important;
    margin-left: .5rem!important;
    border-radius: 9px;
    padding: .2rem;
    display: flex;
    align-items: center;
    border: 1px solid #C4C4C4;
    padding: 0!important;
    text-align: center;
    margin: auto;
    justify-content: center;
    background: #fff;
}
.bb-media-thumb-picker {
    max-height: 53px;
    min-height: auto;
    padding: 0.2rem;
    border-radius: 9px;
    max-width: 100%;
    width: auto;
}

/* PAGE EDITOR - PANEL TYPES */

/* fading banner */
.bb-pe-fb-img {
    max-width:400px;
    height: auto;
}

 .bb-pe-fb-btn {
    background: var(--bb-primary);
}

.bb-pe-fb-display  .bb-pe-fb-btn {
    color: #fff;
}

/* card */
.bb-pe-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 4px #ddd;
    max-width: 180px;
    overflow: hidden;
}

.bb-pe-card-footer {
    padding: 1rem;
}


/* carousel */
.bb-pe-list {
    text-align: left;
    padding-left: 0rem;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.bb-pe-list li {
    background: var(--bb-soft-blue);
    list-style: none;
    border:1px solid var(--bb-mid-blue);
    padding: .25rem .8rem;
    margin: .25rem;
    border-radius: 99px;
    font-weight: bold;
    font-size: .8rem;
}

.bb-slider-img {
    display: flex;
    border-radius: 5px 5px 0 0;
}

/* bb learn */
.bb-learn-input {
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #eee;
}

.bb-learn-submit {
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 5px;
    background: var(--bb-primary)!important;
    color: #fff!important;
    pointer-events: none;
}

/* media panel */

.bb-med-panel-thumb {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.bb-pe-img {
    border-radius: 5px;
}

.bb-pe-img-button-stack {
    border-radius: 5px!important;
    width: 100%;
}

.bb-med-pan-link {
    background: #eee;
    display: flex;
    align-items: center;
    padding: .2rem;
    border-bottom:1px solid #ddd;
}

.bb-med-pan-link:first-child {
    border-radius: 5px 5px 0 0;
}

.bb-med-pan-link:only-child {
    border-radius: 5px;
}

.bb-med-pan-link:last-child {
    border-radius: 0 0 5px 5px;
    border:none;
}

/* bb-map */
.bb-map {
    width:100%;
    height: 400px;
    border:none;
    margin: auto;
}

/* bb accordion */
.bb-accordion-content {
    overflow: hidden;
    word-break: break-all;
}

/* bb-button-stack */
.bb-button-stack {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.bb-button-stack-button {
    text-align: center;
    padding: 1em 0.4em !important;
    margin: .3em !important;
    text-decoration: none;
    text-align: center;
    border-radius: var(--bb-radius);
    background-color: var(--bb-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    color: white !important;
    flex: 46%;
}

.bb-button-stack-button:hover {
    opacity: 0.7;
}

/* bb-social */
.bb-op-share {
    max-width: 110px;
    max-height: 110px;
    margin: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    display: flex;
    width: 200px;
    height: 60px;
}

.bb-op-share a {
    width: 32px;
    color: #fff;
}

.bb-op-share svg {
    fill: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.bb-share-facebook {
    background: #1877F2;
}

.bb-share-twitter {
    background: #333;
}

.bb-share-insta {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.bb-share-youtube {
    background: red;
}

.bb-share-linkedin {
    background: #0a66c2;
}

/* bb-html */
.bbHTML {
    text-align: left;
    overflow-wrap: anywhere;
}


/* PANEL TYPE / COLUMN WIDTH CHOOSER WIDGET */
.bb-pt-item-cont .MuiSelect-select{
    display:flex;
    align-items: center;
}

.bb-pt-item-cont .MuiSelect-select img {
    max-width: 150px;
    margin-right: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 5px #ddd
}


.bb-pt-item-cont ul {
    display: flex!important;
}

.bb-pt-item {
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 5px !important;
    margin: 1rem !important;
    box-sizing: border-box !important;
    padding: .5rem !important;
    font-weight: bold !important;
}

.bb-pt-item img {
    width: 100px;
    border: 1px solid #eee;
    margin-right: 1rem;
}

/* column width */
.bb-cw-item-cont .MuiSelect-select{
    display:flex;
    align-items: center;
    padding: .3rem .5rem;
}

.bb-cw-item-cont .MuiSelect-select img {
    max-width: 200px;
    margin-right: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 5px #ddd;
}

.bb-cw-item {
    box-shadow: 0px 0px 5px #ccc;
    border-radius: 5px !important;
    margin: .5rem !important;
    box-sizing: border-box !important;
    padding: .5rem !important;
    font-weight: bold !important;
}

.bb-cw-img {
    width: 90px!important;
    border: 1px solid #eee;
    margin-right: 1rem;
}

.centered-dropdown {
    position: absolute !important;
    top: 50% !important;
    max-height: 300px !important; /* Ensure the max height is applied */
    overflow-y: auto !important; /* Enable scrolling */
}

.templateImg:hover {
    transform: scale(1.05);
}

.templateImg {
    width: 140px;
    height: auto;
    border-radius: 3px;
    border: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}