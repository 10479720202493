/* HOME PAGE / DASH */
.bb-dash-history {
    background-color: #ffffff;
    opacity: 1;
    background-image: radial-gradient(#c6fff6 0.9px, #ffffff 0.9px);
    background-size: 18px 18px;
    min-height: 100px;
    border-radius: 10px;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.bb-dash-history-links {
    justify-content: center;
    margin-bottom: 1rem;
    margin-top: 0;
    padding-bottom: 1rem;
        margin-left: 0 !important;
}

.bb-dash-history-links a {
    text-decoration: none;
    border:1px solid;
    padding: .5rem;
    margin: 0rem;
    border-radius: 5px;
    display: block;
    background: #fff;
}

.bb-dash-history-links a:hover {
    opacity: .7;
}

.bb-release-banner {
    background-image:  repeating-radial-gradient( circle at 50% 32%, transparent 0, #ffffff 10px ), repeating-linear-gradient( #E2F5F255, #E2F5F2 )!important;
}

.bb-release-notes-img {
    width: 150px;
    height: 150px;
    border-radius: 200px;
    margin: auto;
    margin-top: 1rem;
}

.bb-home-banner {
    background: var(--gunmetal)!important;
    color: #fff!important;
}

.bb-banner-img {
    max-width: 500px;
}

.bb-welcome-name {
    color:var(--light-blue)!important;
}

.bb-intro-banner-header {
    margin-bottom: 1.4rem!important;
    font-size: 2.5rem!important;
}

.bb-card-body {
    min-height: 250px !important;
    padding: 2.5rem !important;
}

.bb-intro-banner-text {
    max-width: 400px!important;
}

.bb-intro-banner-text p {
    font-size: 1.1rem!important;
}
