/* SIDEBAR -----------------------------------------------------------*/
.sidebar {
    border-right: 0px !important;
    padding-right: 0px !important;
    height: calc(100vh - 120px);
    overflow: auto;
}

.sidebar-item {
    width: 100%;
}

.sidebar-item:hover {
    background: var(--bb-secondary)!important;
}

.sidebar-container {
    background-color: #fff;
    border: none !important;
    box-shadow: var(--bb-ui-shadow) !important;
    height: calc(100vh) !important;
    position: fixed;
    padding-top: 5rem;
    top: 0;
    z-index: 1;
}

.sidebar-container ul {
    padding: 1rem 1rem 1rem 1.5rem;

}

.sidebar-container .MuiButtonBase-root {
    width:100%;
    border-radius:5px;
}

.sidebar-item-icon {
    padding-right:.5rem!important;
}
