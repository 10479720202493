/* ANALYTICS ---------------------*/
.bb-analytics-page {
    width:100%;
}

.bb-analytics-dom span:first-child {
    margin-right: 10px;
}

.MuiDataGrid-columnHeaderRow {
    background: var(--bb-secondary)!important;
}

.bb-analytics-dom {
    border:1px solid #cbcbcd;
    border-radius: 10px;
    position: relative;
    padding: .9rem .5rem;
    background: #fff;
}

.bb-analytics-dom legend {
    font-size:.7rem;
    position: absolute;
    top:-18px;
    left:8px;
    color: #64727d;
    background: rgb(248, 247, 255);
    padding: 0.4rem;
}

.bb-analytics-dom label {
    margin-left: 0.5rem;
}

.bb-analytics-date-range {
    background: #fff;
}

.bb-analytics-page .MuiDataGrid-container--top, .bb-analytics-page .MuiDataGrid-container--top [role=row] {
    background: var(--bb-secondary)!important;
    color: #fff;
}

.bb-analytics-tables {
    height: 500px;
    min-height: auto;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 0px 2px #ddd;
    overflow: hidden;
}

.bb-ui-box .MuiDataGrid-toolbarContainer {
    padding: 0.5rem!important;
}

.bb-analytics-big-stat .stat-card-value {
    font-size: max(35px, 2cqw)!important;
}

.bb-ui-box-analytics {
    margin: 1rem 0 !important;
    border-radius: 6px;
    width: 100%!IMPORTANT;
    min-width: 100%!IMPORTANT;
    display: flex !important;
    box-sizing: border-box;
    float: left;
}

.bb-ui-box canvas {
    width:100%!important;
    min-width:100%!important;
    max-width:100%!important;

}

.MuiDataGrid-main, .MuiDataGrid-withBorderColor, .MuiDataGrid-root, .MuiDataGrid-root--densityCompact, .MuiDataGrid-withBorderColor {
    border-radius: 0px!important;
}

.bb-analytics-titles {
    position: relative;
    top: 15px;
    left: 14px;
    height: 0;
    line-height: 0;
    padding: 0;
    margin: 0 !important;
}