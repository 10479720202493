/* This file contains the styling for the panel previews */

/* Slider Preview Styling */
.slider * {
    box-sizing: border-box;
}

.slider {
    width: 400px;
    text-align: center;
    overflow: hidden;
    margin: auto;
}

.slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type:x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-snap-points-x: repeat(300px);
}

.slides span {
    font-size: 1rem !important;
}

.slides h5 {
    font-size: 1rem !important
}

.slides::-webkit-scrollbar {
    width: 3px;
    height: 25px;
}

.slides::-webkit-scrollbar-thumb {
    background: var(--bb-primary);
    border-radius: 15px;
}

.slides::-webkit-scrollbar-track {
    background: var(--bb-soft-green);
    border:1px solid var(--bb-primary);
}

.slides>.slide-cont {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 400px;
    min-height: 400px;
    margin-right: 50px;
    border-radius: 10px;
    background: #eee;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slides .slide-cont:target {
    transform: scale(0.8);
}

.author-info {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 0.75rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
}

.author-info a {
    color: white;
}

.slider .slide-link {
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: .9rem;
    padding: 0 5px !important;
    width: 19px !important;
    min-width: auto;
    color: #666;
}

.slide-number {
    background: var(--bb-primary);
    color: #fff;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    padding: 10px 15px;
    right: 5px;
    opacity: 0.9;
}

.bb-slider-card {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important;
    position: relative;
}

.slider-links-container {
    display: flex;
    justify-content: space-around;
}

/* FADING BANNER Preview Styling */
.bb-pe-fading-banner {
    display: flex;
    flex-direction: column;
    width:320px;
    margin: auto;
}

/* Fading Banner colSpan12 Desktop Styling */
.bb-col-span-12 .bb-pe-fading-banner {
    flex-direction: row-reverse!important;
    width:100%;
    margin-bottom: 1rem;
}

.bb-col-span-12 .bb-pe-fading-banner img {
    max-width:52%;
}

.bb-col-span-12 .bb-pe-fb-display {
    margin-right: auto;
    justify-content: center;
    margin-left: 1rem;
}

/* Fading Banner colSpan12 Mobile Styling */

@media (max-width: 1000px) {
    .bb-col-span-12 .bb-pe-fading-banner {
        flex-direction: column!important;
        width: 100%;
        margin-bottom: 1rem;
    }

    .bb-col-span-12 .bb-pe-fading-banner img {
        max-width: 100%;
    }

    .bb-col-span-12 .bb-pe-fb-display {
        margin-right: 0;
        justify-content: center;
        margin-left: 0;
    }

    .bb-med-panel-img {
        padding: 0.5rem;
        background: #eee;
        border-radius: 5px;
    }
}

/* Fading Banner Fading Animation Settings */

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

/* Fading Banner Dots Styling */

.dots-arrows-container {
    display: flex;
    align-items: center;
    margin: 10px 0; /* Adjust margins to position the container */
}

.dots-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Increase gap between dots */
}

.dot {
    width: 10px; /* Increase width */
    height: 10px; /* Increase height */
    border-radius: 50%;
    border: 1px solid #11a681;
    background-color: #f4f4f4;
    transition: background-color 0.3s;
}

.dot.active {
    background-color: #11a681;
}

/* Fading Banner Arrows Styling */

.arrow-button {
    background-color: #11a681!important;
    border: 1px solid #11a681!important;
    border-radius: 50%;
    padding: 5px;
    margin: 0 3px;
    transition: background-color 0.3s, border-color 0.3s;
}

.arrow-button:hover {
    background-color: #f0f0f0;
    border-color: #aaa;
}

.arrow-icon {
    color: #ffffff;
    font-size: 24px;
    padding-left: 4px;
}

/* fix for Img Panel */
.bb-med-panel-img {
    padding: 0.5rem;
    background: #eee;
    border-radius: 5px;
}

/* Item List Preview Styling */

.ItemList img {
    width: 100%;
    height: auto;
    border: solid 1px transparent;
    border-radius: 15px;
    padding: 0.5rem;
}

.ItemListLinks {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap; /* Allow wrapping */
}

.link-name {
    background-color: #f0f0f0;
    padding: 5px;
    margin-right: 10px;
    border-radius: 4px;
    width: 90px; /* Set a fixed width for all link names */
    text-align: left; /* Ensure text is left-aligned */
    flex-shrink: 0; /* Prevent shrinking */
}

.link-value {
    text-align: left; /* Ensure text is left-aligned */
    margin-left: 10px; /* Small gap between link name and value */
    flex-grow: 1; /* Ensure link values take up remaining space */
    word-wrap: break-word; /* Ensure text wraps within the available space */
    white-space: normal; /* Allow text to wrap */
}

@media (max-width: 1400px) {
    .ItemListLinks {
        flex-direction: column!important;
        align-items: flex-start; /* Align items to the start */
        width: 100%;
        margin-bottom: 1rem;
    }

    .link-value {
        margin-left: 0; /* Remove left margin */
        margin-top: 10px; /* Add top margin for spacing */
    }
}

.bbHTML iframe {
    max-width: 100%;
    width: 100%;
    height: auto;
}

/* Event List Preview Styling */

.bb-event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bb-event-card {
    width: 40%;
    border-radius: 4px!important;
    overflow: hidden;
}

.bb-event-image {
    height: 140px;
    object-fit: cover;
}

.bb-event-content {
    padding: 16px;
    text-align: left; /* Align text to the left */
}

.bb-event-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left; /* Align text to the left */
}

.bb-event-description {
    color: #666;
    margin-top: 8px;
    text-align: left; /* Align text to the left */
}

.bb-event-detail {
    display: flex;
    align-items: center;
    margin-top: 8px;
    text-align: left; /* Align text to the left */
}

.bb-event-icon {
    margin-right: 8px;
    font-size: small;
}

.dots-arrows-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.arrow-button {
    padding: 8px;
    margin: 0 15px!important;
}

.arrow-icon {
    font-size: 24px;
}

.dots-container {
    display: flex;
    align-items: center;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 4px;
}

.dot.active {
    background-color: #11a681;
}