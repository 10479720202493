/* NAV EDITOR */
.bb-nav-form h5 {
    text-align: left;
    font-size: 1rem;
    padding-left: 0.5rem;
    margin-bottom: 0;
    padding-bottom: 0;
}

.bb-nav-form hr {
    display: none;
}

.bb-nav-form div {
    box-shadow:none!important;
    padding: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.bb-nav-form .MuiPaper-root{
    background: none!important;
}

.bb-nav-form .form-group .MuiGrid-container:not(:first-child){
    background: #eee;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    margin-top: 0 !important;
}

.bb-nav-form .form-group .MuiGrid-container:not(:first-child) .MuiGrid-item {
    padding-top: 10px!important;
}

.bb-nav-form .form-group .MuiGrid-container:not(:first-child) .mui-grid-item:last-child {
    padding: 0;
}

.bb-nav-form .form-group .MuiGrid-container:last-child {
    background: none;
}

.bb-nav-form .form-group .MuiGrid-container:not(:first-child) input {
    background: #fff;
    /*padding: 0.5rem;*/
    border-radius: 10px;
}

.bb-nav-form .form-group .MuiGrid-container .array-item-add {
    background: var(--bb-primary);
}

.bb-nav-form .form-group .MuiGrid-container .array-item-add svg {
    fill: #fff;
}

#root_nav__title {
    display: none;
}