/* Style for the editor container */
.editor-container {
    max-width: 900px;
    margin: 1rem auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
}

/* Hover effect for the editor container */
.editor-container:hover {
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.15);
}

/* Custom Quill toolbar */
.ql-toolbar {
    border: none;
    background-color: #f4f4f4;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

/* Quill editor area */
.ql-container {
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    padding-left: 1rem;
    padding-right: 1rem;
}

/* Custom scrollbar for the editor */
.ql-editor {
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
}

.ql-editor::-webkit-scrollbar {
    width: 8px;
}

.ql-editor::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.ql-editor::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}

/* Style for text inside the editor */
.ql-editor p {
    margin-bottom: 1rem;
}

/* Responsive behavior */
@media (max-width: 768px) {
    .editor-container {
        padding: 0.8rem;
        margin: 1.5rem auto;
    }

    .ql-editor {
        min-height: 250px;
        font-size: 0.9rem;
    }
}