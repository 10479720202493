/* NOTIFICATIONS PAGE */
.bb-ui-box-notifications {
    margin: 1rem 0 !important;
    border-radius: 6px;
    width: 100%!IMPORTANT;
    min-width: 100%!IMPORTANT;
    display: flex !important;
    box-sizing: border-box;
    float: left;
}

.bb-notifications-table .MuiDataGrid-row {
    border-bottom:1px solid #eee;
    border-left:1px solid #eee;
    border-right:1px solid #eee;
}

.bb-notifications-table .MuiDataGrid-row .MuiChip-filled {
    background: var(--bb-soft-green);
    color: #111;
    margin-right: .5rem;
    padding-bottom: .4rem;
    padding-top: .4rem;
}

.bb-notifications-table .MuiDataGrid-row:hover {
    cursor: pointer;
}

.bb-recipients {
    background: #eee;
    border-radius: 5px;
    align-items: center;
    padding: .5rem;
    padding-left: 1rem;
}

.bb-recipient-count {
    background: var(--bb-primary);
    padding: 0 .5rem;
    color: #fff;
    border-radius: 99px;
    font-weight: bold;
    min-width: 50px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
    margin-top: .5rem;
}

.bb-notifications-label .MuiFormControlLabel-label {
    font-weight: bold;
}

.bb-notification-container {
    background: #f6f6f6;
    padding: 1rem;
    border-radius: 5px;
    border:1px solid #eee;
}

.bb-notification-container input, .bb-notification-container textarea {
    background: #fff;
    border-radius: 10px;
}

.bb-notifications-check {
    background: #f6f6f6;
    padding: 0 .5rem !important;
    border-radius: 5px;
    margin-bottom: 1rem!important;
    border: 1px solid #eee;
}

.bb-analytics-tables {
    height: 500px;
    min-height: auto;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 8px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 0px 2px #ddd;
    overflow: hidden;
}

/* Audience */
.bb-notifications-list {
    overflow: auto;
    max-height: 260px;
    background: #eee;
    padding: 1rem!important;
    border-radius: 5px;
}

.bb-notifications-sub-list {
    background: #fff;
    border-radius: 5px;
    padding: .5rem;
}