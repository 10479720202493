.ColorTabs {
    margin-bottom: 1rem;
}

.ColorPicker {
    margin-bottom: 1.5rem;
    width: 100%!important;
}

.PresetTitle {
    width: 100%;
    margin-top: 1rem;
}

.ButtonBox {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.SelectButton {
    background-color: #11a681 !important;
    color: white !important;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.SelectButton:hover {
    background-color: #0e8a6b !important;
    color: #e0f7f1 !important;
    transform: scale(1.05);
}

.PresetButton {
    background-color: white !important;
    color: #11a681 !important;
    border-color: #11a681 !important;
    border-style: solid !important;
    border-width: 1.5px !important;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.PresetButton:hover {
    background-color: #e0f7f1 !important;
    color: #0e8a6b !important;
    border-color: #0e8a6b !important;
    transform: scale(1.05);
}

.CheckerButton {
    background-color: #11a681 !important;
    color: white !important;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.CheckerButton:hover {
    background-color: #0e8a6b !important;
    color: #e0f7f1 !important;
    transform: scale(1.05);
}